.add-deposito-modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  width: 100%;
  gap: 10px;

  @media screen and (min-width: 768px) {
    max-height: 60vh;
    overflow-y: auto;
  }

  .block-section-header,
  .block-section-header p {
    margin: 0;
  }

  .piani-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px dashed #e0e0e0;
    border-radius: 6px;
    padding: 8px;
    width: 100%;
    margin-top: 10px;

    .piano-remove-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
      border: none;
      background-color: white;
    }

    .block-section-header {
      margin-top: 16px;
    }

    .scaffali-container {
      position: relative;
      display: flex;
      flex-direction: column;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      padding: 8px;
      width: 100%;

      .scaffale-remove-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
        border: none;
        background-color: white;
      }
    }
  }
}
