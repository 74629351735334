// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
// }
// .feature-table-container {
//   box-sizing: border-box;
//   width: 518px;
//   height: 453px;
//   border-radius: 6px;
//   border: 1px solid rgba(221, 221, 221, 1);
//   display: flex;
//   flex-direction: column;
//   .feature-table-header {
//     width: 100%;
//     height: 52px;
//     border-radius: 6px 6px 0px 0px;
//     border-bottom: 1px solid rgba(225, 86, 21, 1);
//     background: rgba(248, 218, 204, 1);
//     display: flex;
//     .feature-table-header-column {
//       width: 150px;
//       border-radius: 6px 0px 0px 0px;
//       border-right: 1px solid rgba(225, 86, 21, 1);
//       padding-left: 20px;
//     }
//     .feature-table-header-description {
//       width: calc(100% - 150px);
//       padding-left: 20px;
//       border-right: 1px solid rgba(225, 86, 21, 1);
//     }
//     .table-header-text {
//       display: flex;
//       align-items: center;
//       justify-content: left;
//       height: 100%;
//       font-size: 18px;
//       font-weight: 400;
//       line-height: 22.05px;
//       letter-spacing: 0.02em;
//       @media (max-width: 520px) {
//         font-size: 14px;
//         line-height: normal;
//       }
//     }
//   }
//   .feature-table-body-container {
//     height: calc(100% - 52px);
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     background-color: rgba(242, 242, 242, 1);
//     overflow-y: auto;
//     &::-webkit-scrollbar {
//       width: 4px;
//       border-radius: 4px;
//       background-color: rgba(242, 242, 242, 1);
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: rgba(225, 86, 21, 1);
//       border-radius: 4px;
//     }
//     &::-webkit-scrollbar-button {
//       display: none;
//     }
//     .line-separator {
//       border-top: 1px solid rgba(221, 221, 221, 1);
//     }
//     .feature-table-data {
//       display: flex;
//       .feature-table-body-column {
//         width: 150px;
//         padding: 10px;
//         border-radius: 6px 0px 0px 0px;
//         border-right: 1px solid rgba(225, 86, 21, 1);
//       }
//       .feature-table-body-description {
//         width: calc(100% - 150px);
//         height: fit-content;
//         padding: 15px;
//         display: flex;
//         flex-direction: column;
//         gap: 20px;
//         border-right: 1px solid rgba(225, 86, 21, 1);
//         .description-text-container {
//           font-size: 18px;
//           font-weight: 400;
//           line-height: 25.2px;
//           letter-spacing: 0.02em;
//           text-align: left;
//           @media (max-width: 520px) {
//             font-size: 14px;
//             line-height: normal;
//           }
//         }
//         .description-image-container {
//           width: 100%;
//           height: 191px;
//           border-radius: 6px;
//           border: 1px solid rgba(221, 221, 221, 1);
//           object-fit: cover;
//         }
//       }
//     }
//   }
// }
.feature-summary-container .feature-table-container {
  width: 100% !important;
  //height: 100% !important;
  /* overflow: hidden; */
  display: flex !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
.feature-table-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  overflow-x: auto;
  height: -webkit-fill-available;
  .feature-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd; /* Border for the entire table */
    border-radius: 5px;
    overflow-y: auto;
  overflow-x: auto;
    th,
    td {
      border: 1px solid #e4865a; /* Border for table cells */
      padding: 8px;
      text-align: left;
    }

    .feature-table-header {
      background-color: #F8DACC;
      font-weight: bold;
    }

    .feature-table-body-container {
      tr:nth-child(even) {
        background-color: #F8DACC;
      }

      .feature-table-data:hover {
        background-color: #e9e9e9;
      }
    }
  }
}

.cassette-link,
.Reperti-link {
  /* Your link styles here */
  color: blue;
  text-decoration: none;
}
.Counter{
  color:#fa4f00;
}
.cassette-link{color:#fa4f00;}
.Reperti-link{color:#fa4f00;}

.db-reference {
  background-color: #F8DACC;
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}
