.sign-in-form {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 381px;
  width: 484px;
  flex-direction: column;
  @media (max-width: 520px) {
    width: 90%;
  }
  .header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      text-align: left;
      color: #000000;
    }
    .description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #6e6e6e;
    }
  }
  .password-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .label-container {
      display: flex;
      justify-content: space-between;
      .password-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #000000;
      }
      .forgot-password {
        color: #e15615;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
  .button-login-container {
    margin-top: 20px;
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 6px;
    border: 1px solid #e15615;
    background: #e15615;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    .button-text {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
}

@media (max-width: 991px) {
  .title,
  .description,
  .password-container {
    max-width: 100%;
  }

  .password-container {
    flex-wrap: wrap;
  }
}