.layer-upload-modal-container {
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;

  .layer-upload-modal-body {
    padding: 1rem;
  }
}
