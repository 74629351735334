/* CustomModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 600px;

  @media (max-width: 520px) {
    max-height: 50%;
    height: fit-content;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.modal-container {
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modal-header {
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid rgba(225, 86, 21, 1);
  background-color: #f8dacc;
  width: 100%;
  padding: 14px 20px;
}

.modal-header-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.modal-footer {
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid rgba(225, 86, 21, 1);
  background-color: #f8dacc;
  width: 100%;
  padding: 14px 20px;
  display: flex;

  .modal-footer-button {
    color: #e15615;
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid #e15615;
    padding: 8px 16px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      background-color: #e15615;
      color: white;
    }

    &.disable {
      background-color: #f8dacc;
      color: #e15615;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
