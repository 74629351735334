/* Modal.css */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  @media (max-width: 520px) {
    align-items: flex-end;
    max-height: 100vh;
    position: fixed;
  }
}

.modal-content {
  background: white;
  border-radius: 8px;
  position: relative;
  max-width: fit-content;
  @media (max-width: 520px) {
    max-height: 50%;
    height: fit-content;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
