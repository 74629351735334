.municipality-info-accordion {
  padding: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.option-card-main-container .municipality-info-children-wrappper {
  padding: 0;
}

.sub-accordion-wrapper{
  width: calc(100% - 10%);
  margin-left: 20px;
}

.sub-accordion{
  width: 100%;
  background-color: #F7F7F7;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
  position: relative;
  .node-line{
    position: absolute;
    top: 20px;
    width: 15px;
    z-index: 1;
    left: 28px;
    border-bottom: 2px solid #E15615;
  }
}

.layers-children-wrapper{
  border-top: 1px solid #E15615;
  width: 100%;
}
.layers--scrollable-wrapper{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}