.delete-modal-body {
  background-color: #fff;
  border-radius: 8px;
}

.delete-modal-content {
  padding: 20px;
}

.delete-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.delete-modal-itemname {
  font-size: 32px;
  font-weight: bold;
  color: #555;
}
