.open-layer-map-main-container {
  position: relative;
  width: 100%;
  height: 100%;
  .open-layer-map-sub-container {
    width: 100%;
    height: 680px;
    overflow: hidden;
    border-radius: 5px;
    .ol-viewport {
      display: none;
    }
    .ol-viewport:first-child {
      display: block;
    }
    .ol-zoom {
      display: flex;
      flex-direction: column;
      gap: 10px;
      top: auto;
      bottom: 20px;
      left: 20px;
      padding: 2px;
      background-color: #fff;
      border-radius: 10px;
      .ol-zoom-in,
      .ol-zoom-out {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e15615;
        color: #fff;
        font-size: 28px;
        font-weight: 300;
        height: 28px;
        width: 28px;
        padding: 0;
        border-radius: 10px;
        line-height: normal;
        margin: 5px;
        &:hover,
        &:active,
        &:focus {
          border: none;
          outline: none;
        }
      }
      .ol-zoom-in:hover {
        cursor: zoom-in;
      }
      .ol-zoom-out:hover {
        cursor: zoom-out;
      }
      .ol-zoom-in::after {
        position: absolute;
        content: "";
        width: 30px;
        height: 1px;
        background-color: rgba(124, 124, 124, 0.695);
        bottom: -10px;
        border-radius: 10px;
      }
    }
    .ol-attribution.ol-uncollapsible {
      display: none;
    }
  }
}
