.toggle {
  position: relative;
  width: fit-content;
  border-radius: 10rem;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-top: 0;
  user-select: none;
  font-size: 12px
}

.toggle.toggle-on {
  padding: 4px 1rem 4px 1.5rem;
  background-color: green;
  color: white;
}
.toggle.toggle-off {
  padding: 4px 1.5rem 4px 1rem;
  background-color: gray;
  color: white;
}

.toggle-switch {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  top: 50%;
  border-radius: 100%;
  background-color: white;
  transform: translateY(-50%);
}

.toggle.toggle-on .toggle-switch {
  left: 0.5rem;
}

.toggle.toggle-off .toggle-switch {
  right: 0.5rem;
}

@media screen and (max-width: 576px) {
  .toggle.toggle-on {
    padding: 4px 0.5rem 4px 1.25rem;
    background-color: green;
    font-size: 10px;
    color: white;
  }
  .toggle.toggle-off {
    padding: 4px 1.25rem 4px 0.5rem;
    background-color: gray;
    font-size: 10px;
    color: white;
  }

  .toggle-switch {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: 50%;
    border-radius: 100%;
    background-color: white;
    transform: translateY(-50%);
  }

  .toggle.toggle-on .toggle-switch {
    left: 0.25rem;
  }

  .toggle.toggle-off .toggle-switch {
    right: 0.25rem;
  }
}
