.file-upload-section {
  border-radius: 6px;
  border: 1px dashed rgba(221, 221, 221, 1);
  background-color: #f2f2f2;
  align-self: center;
  display: flex;
  // height: 304px;
  width: 100%;
  max-width: 600px;
  flex-direction: row;
  gap: 2rem;
  padding: 1rem 2rem;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  justify-content: center;

  .file-upload-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    // margin-top: 42px;
    justify-content: center;

    label {
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #6e6e6e;
      }
    }

    .file-upload-info {
      color: #6e6e6e;
      text-align: left;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      line-height: 16px;
      letter-spacing: 0.36px;
      font-size: 12px;
      // margin-top: 18px;
    }
  }

  @media (max-width: 520px) {
    padding: 0px;
  }
}
