.input-component {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 520px) {
    width: 100%;
  }
  .label-container-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .password-visible-icon {
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .password-input-container {
    width: 484px;
    display: flex;
    padding-right: 10px;
    border: 1px solid #dddddd;
    border-radius: 6px;
    gap: 5px;
    @media (max-width: 520px) {
      width: 100%;
    }
    .reset-password-input {
      width: 100%;
      border: none;
      letter-spacing: 1px;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      background-color: transparent;
      &:focus {
        outline: none;
      }
    }
    .password-visible-icon {
      cursor: pointer;
    }
  }
}
