.upload-file-info-wrapper {
  width: 429px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 520px) {
    transform: translate(0%, 0%);
  }
  .option-card-children-wrapper {
    padding: 0px;
    padding-top: 12px;
    gap: 20px;
    overflow: visible;
  }
}
.file-name-text-wrapper {
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 18px;
  display: flex;
  .file-name-text {
    width: 100%;
    min-height: 48px;
    background-color: #eeeeee;
    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 8px;
  }
}
.upload-file-info-select-input {
  width: 100%;
  padding: 0 16px;
}
.upload-file-info-button-wrapper {
  width: 100%;
  border-top: 1px solid #e15615;
}
