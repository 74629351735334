.modal-upload-section-container {
  width: 100%;
  background-color: white;
  padding: 1rem;
  height: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;

  .modal-upload-section-content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    justify-content: center;

    .origin-add-btn-wrapper {
      width: 100%;

      button {
        width: 100%;
        background-color: #e15615;
        color: white;
        border: none;
        border-radius: 6px;
        padding: 0.5rem;
        font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.36px;
        cursor: pointer;

        &:hover {
          background-color: #e15615dd;
        }
      }
    }
  }

  .comune-cassetta-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px dashed rgba(221, 221, 221, 1);
    background-color: #f2f2f2;
  }

  .modal-upload-match-section {
    display: flex;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px dashed rgba(221, 221, 221, 1);
    background-color: #f2f2f2;
  }
}

.group-selection-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  border-radius: 6px;
  border: 1px dashed rgba(221, 221, 221, 1);
  background-color: #f2f2f2;
}
