.add-movimentazione-modal {
  padding: 8px;

  @media screen and (min-width: 520px) {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }

  .input-select-wrapper {
    label {
      color: black;
      margin-top: 10px;
    }
  }

  .autorizzazione-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;

    label.autorizzazione-icon-wrapper {
      cursor: pointer;
    }

    .autorizzazione-icon-wrapper {
      border: none;
      background: white;
      margin-left: auto;
      width: 24px;
      height: 24px;
    }
  }
}

.movimentazione-date-time-picker {
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  .movimentazione-date-time-picker-header {
    display: flex;
    align-items: center;
    .date-time-picker-icon {
      margin-left: auto;
      width: 24px;
      height: 24px;
      padding: 0;
      border: none;
    }
  }
  & > p {
    margin-top: 8px;
    padding: 12px 20px;
    background-color: #f5f5f5;
    border-radius: 6px;
  }
}

.movimentazione-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
}

.modal-footer-button:disabled {
  background-color: #f5f5f5;
  color: #b3b3b3;
  cursor: not-allowed;
  
  &:hover {
    background-color: #f5f5f5;
    color: #b3b3b3;
  }
}