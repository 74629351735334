.modal-upload-section-wrapper {
  padding: 1rem;
  width: 100%;
  border-radius: 6px;
  border: 1px dashed rgba(221, 221, 221, 1);
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
