.block-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .block-header-buttons {
    display: flex;
    align-items: center;
  }

  button {
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}
