
.groups-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100&;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 12px;
}

.groups-space-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem; /* Space between badges */
  margin-bottom: 1rem;
}

.groups-header {
  margin-top: 1rem;
  //border-top: 0.2rem solid rgba(221, 221, 221, 1);
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.groups-header-title {
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}


.group-name-container {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 1rem; /* Makes the badge oval */
  font-size: 14px;
  font-weight: 500;
  color: #fff; /* Text color */
  text-align: center;
  position: relative; /* To position the delete button */
  background-color: #007bff; /* Default background color */
}

.group-name-container .delete-button {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-color: #dc3545; /* Red color for the delete button */
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%); /* Center the button vertically */
  gap: 0.1rem; /* Space between the icon and the text */
}

.group-name-container .delete-button:hover {
  background-color: #e15615; /* Darker red on hover */
}


.group-name-text {
  padding-right: 1.5rem; /* Add space for the delete button */
}

.group-delete-icon {
  margin-top:0;
  padding: 0.5rem;
  font: 400 12px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.groups-icon {
  margin: 1rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.groups-button {
  display: flex;
  border: 0rem;
  background: #fff;
  cursor: pointer;
}

/*
.groups-button {
  width: 12rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: 400 14px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  // color: #fff;
  //background-color: rgba(221, 221, 221, 1);;
  border: 0.2ren solid rgba(221, 221, 221, 1);
  margin-right: 1rem;
  border-radius: 6px;
  cursor: pointer;
  gap: 1rem; 
}
  */



