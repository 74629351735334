.annotation-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.annotation-section-wrapper {
  padding: 1rem;
  width: 100%;
  border-radius: 6px;
  border: 1px dashed rgba(221, 221, 221, 1);
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  .annotation-inputs-wrapper {
    display: flex;
    gap: 0.5rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .annotation-input-wrapper {
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    label {
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.36px;
    }

    input {
      width: 100%;
      border: 1px solid #6e6e6e;
      border-radius: 6px;
      padding: 0.5rem;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.36px;
      color: #6e6e6e;
    }
  }

  .annotation-delete-wrapper {
    display: flex;

    button {
      margin-left: auto;
      border: none;
      cursor: pointer;
    }
  }
}
