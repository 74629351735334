.input-select-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  
    & > div {
      display: flex;
      width: 100%;
      gap: 0.5rem;
      & > div {
        width: 100%;
      }
      input {
        border-radius: 6px;
        border: 1px solid #6e6e6e;
        padding: 0.5rem;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  
    .input-select-label {
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      text-align: left;
      line-height: 16px;
      letter-spacing: 0.36px;
    }
  }
  