.accordion-main-container {
  width: 100%;
  background-color: #F7F7F7;
  color: #E15615;
  .accordion-header {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.disable-accordion {
      opacity: 0.6;
    }
    .accordion-title-and-dropdown-wrapper{
      display: flex;
      align-items: center;
      gap: 10px;
      .accordion-title {
        font-size: 18px;
        color: inherit;
        user-select: none;
        color: inherit;
        font-weight: 500;
      }
    }
  }
  .default-icon {
    display: flex;
    align-items: center;
    transition: all 0.5s;
    width: 24px;
    height: 24px;
  }
  .drop-down-icon {
    transform: rotate(180deg);
  }
  .arrow-icon-rotate {
    transform: rotate(0deg);
  }
  .accordion-header-and-connector-wrapper {
    position: relative;
    padding-left: 50px;
    .node-accordion-connector {
      position: absolute;
      left: 25px;
      bottom: 19px;
    }
  }
  .accordion-children-wrapper {
    position: relative;
    &::before{
      content: "";
      position: absolute;
      top: 0px;
      width: 15px;
      height: calc(100% - 20px);
      z-index: 1;
      left: 27px;
      border-left: 2px solid #E15615;
      border-bottom: 2px solid #E15615;
      border-radius: 0 0 0 3px;
    }
  }
}
.active-eye-icon{
  background-color: #F8DACC;
}
.highlight-title {
  font-weight: 900!important;
  color: #E15615 !important;
}