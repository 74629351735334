// .class-summary-add-modal {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   border-radius: 0.5rem;
//   background-color: white;
// }

// .class-summary-add-modal-title {
//   font-size: 1.5rem;
//   font-weight: bold;
//   padding-bottom: 1rem;
//   border-bottom: 1px solid #e0e0e0;
//   margin: 0;
// }

// .class-summary-add-modal-content {
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   padding: 0 1rem;
// }

// .class-summary-add-modal-form {
//   width: 400px;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
// }

// .class-summary-add-modal-form label {
//   font-size: 1.25rem;
//   font-weight: 500;
// }

// .class-summary-add-modal-form input {
//   padding: 0.5rem;
//   border: 1px solid #e0e0e0;
//   border-radius: 0.25rem;
// }

// .class-summary-add-modal-btns {
//   display: flex;
//   gap: 1rem;
//   justify-content: flex-end;
//   border-top: 1px solid #e0e0e0;
//   padding-top: 1rem;
// }

.add-provenienze-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .input-label {
      font-size: 16px;
      font-weight: 500;
      color: #444050;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
