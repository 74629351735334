.select-background-container {
  width: 400px !important;
  height: 250px;
  position: absolute;
  top: 330px;
  right: 100px;
  border-radius: 6px;
  .select-background-option-body-container {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    .select-background-item-container {
      width: 100%;
      height: 24px;
      .select-background-item {
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        width: fit-content;
        .select-background-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #e15615;
        }
      }
    }
  }
}
