.select-main-container {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 18px;
  color: #e15615;

  .select-box-wrapper {
    position: relative;
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .selected-value {
      width: 100%;
      height: 48px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #EEEEEE;
      padding: 8px;
      font-size: 18px;
      font-weight: 500;
      color: #e15615;
      border-radius: 5px;
      border: 1px solid #DDDDDD;
      display: flex;
      gap: 2px;
      align-items: center;
      justify-content: space-between;
      .select-drop-down-icon {
        transform: rotate(-180deg);
        transition: transform 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
      }
      .select-rotate-drop-down-icon {
        transform: rotate(0deg);
      }
    }
    .options-container {
      width: 100%;
      max-height: 160px;
      white-space: wrap;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #DDDDDD;
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      &.overlapped{
        position: absolute;
        top: 105%;
        left: 0px;
        z-index: 1;

      }
      .list-child {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #6E6E6E;
        text-align: start;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
      }
      .selected-value-in-list {
        color: #e15615;
        cursor: pointer;
      }
    }

    .options-container::-webkit-scrollbar {
      display: none;
    }
  }

  .select-label-text {
    font-size: 18px;
    color: black;
    text-align: start;
  }

  .select-disabled {
    opacity: 0.6;
    cursor: default;
  }
}
