.editor-header {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.editor-header p {
  margin: 0;
}

.editor-header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.editor-header .editor-header-status-badge {
  font-size: 0.8rem;
  font-weight: 400;
  width: fit-content;
  padding: 0.5rem 0.5rem;
  border-radius: 10rem;
  border: #e15615d3 1px solid;
  background-color: #e15615;
  color: white;
}

.editor-header p.editor-header-status {
  font-weight: 600;
  display: flex;
  gap: 1rem;
}

.editor-header-title-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.editor-header .editor-header-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.editor-header .editor-header-version {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
  background-color: #e15615;
  padding: 0.1rem 0.5rem;
  margin-right: 2rem;
  border-radius: 10rem;
}

.editor-header .editor-header-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.editor-header .editor-header-buttons .editor-header-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  background-color: #e15615;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.editor-header .editor-header-buttons .editor-header-btn.inactive {
  background-color: #e1561562;
  cursor: not-allowed;
}

@media screen and (max-width: 992px) {
  .editor-header-info {
    flex-direction: column;
    gap: 1rem;
  }

  .editor-header-buttons {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .editor-header .editor-header-title-wrapper {
    align-items: center;
    flex-direction: column;
  }

  .editor-header p.editor-header-status {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .editor-header .editor-header-buttons {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
