.autori-table-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 24px;

  .autori-header-title {
    padding: 0;
    font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    margin-left: 1rem;
  }

  .autori-header {
    margin-top: 2rem;
    //border-top: 0.2rem solid rgba(221, 221, 221, 1);
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(221, 221, 221, 1);
  }

  .autori-table-header {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 0.2rem solid rgba(221, 221, 221, 1);
    border-bottom: 0.2rem solid rgba(221, 221, 221, 1);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background-color: #fff;
    padding: 16px 20px;
    font: italic 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .autori-row {
    align-items: center;
    display: flex;
    gap: 30px;
    flex: 1;
    gap: 1rem;
    border-bottom: 1px solid rgba(221, 221, 221, 1);
    border-bottom-width: 1px;
    background-color: #fff;
    padding: 4px 20px;

    .autori-row-item-text {
      color: #616161;
      align-self: stretch;
      margin: auto 0;
      display: flex;
      font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      width: 22%;
      min-width: 300px;
      // @media (max-width: 1435px) {
      // }
      word-break: break-all;
    }
  }
}

.autori-button {
  border: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.autori-rowt > * {
  padding: 12px 0;
}

.autori-title-text {
  background-color: #fff;
  padding: 12px 0;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.autori-table-actions {
  margin-left: auto;
}

.autori-icons {
  margin: 0.5rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.autori-save-button {
  border-radius: 6px;
  border: 1px solid white;
  background-color: rgb(225, 86, 21);
  align-self: center;
  display: flex;
  gap: 8px;
  font-size: 18px;
  color: white;
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 6px 12px;
  cursor: pointer;
  &:hover {
    background-color: rgb(225, 86, 21, 0.8);
  }
}

.autori-cancel-button {
  border-radius: 6px;
  border: 1px solid rgb(225, 86, 21);
  background-color: white;
  align-self: center;
  display: flex;
  gap: 8px;
  font-size: 18px;
  color: black;
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 6px 12px;
  cursor: pointer;
  &:hover {
    background-color: rgb(225, 86, 21, 0.1);
  }
}

.class-summary-add-modal-input {
  display: flex;
  flex-direction: column;
  input {
    font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    border-radius: 6px;
    border: 1px solid rgb(221, 221, 221);
    margin-top: 8px;
    padding: 12px 20px;
    height: 48px;
  }
}
