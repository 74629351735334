.home-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden; // Add scroll bar in case of overflow
  @media (max-width: 520px) {
    width: 90%;
    @media (max-height: 700px) {
      width: 90%;
      padding-top: 300px;
    }  
  }

  .home-intro {
    font-weight: 500;
    color: #000;
    text-align: center; // Align text to right
    width: 100%;
    text-wrap: wrap;
    word-break: keep-all;
    font: 42px/56px Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .home-description {
    font-weight: 200;
    color: #000;
    text-align: center;
    width: 100%;
    text-wrap: wrap;
    word-break: keep-all;
    font: 28px/39px Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .button-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    margin-top: 36px;
    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .home-button {
    align-items: center;
    height: 250px;
    width: 350px;
    border-radius: 32px;
    border: 1px solid rgba(225, 86, 21, 1);
    background-color: #e15615;
    align-self: center;
    display: flex;
    flex-direction: column; // Order elements in column
    margin-top: 36px;
    gap: 12px;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    letter-spacing: 0.36px;
    padding: 30px 20px;
    cursor: pointer;
  }
}

// @media (max-width: 991px) {
//   .description {
//     max-width: 100%;
//   }
// }

@media (max-width: 991px) {
  .home-button {
    white-space: initial;
  }
}

.home-text {
  align-self: center;
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
  font-weight: bolder;
}

.home-icon {
  width: 28px;
  height: 28px;
}

.save-button {
  border-radius: 6px;
  border: 1px solid rgba(225, 86, 21, 1);
  background-color: #e15615;
  align-self: center;
  display: flex;
  gap: 12px;
  font-size: 18px;
  color: #fff;
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 10px 20px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .save-button {
    white-space: initial;
  }
}

.cancel-button {
  border-radius: 6px;
  border: 1px solid rgba(225, 86, 21, 1);
  background-color: #ffff;
  align-self: center;
  display: flex;
  gap: 12px;
  font-size: 18px;
  color: #000;
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 10px 20px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .cancel-button {
    white-space: initial;
  }
}
