.filter-container {
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-width: 496px;
  width: 532px;
  padding-bottom: 28px;
}
.filter-header {
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid rgba(225, 86, 21, 1);
  background-color: #f8dacc;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 20px;
}
.header-title {
  color: #e15615;
  letter-spacing: 0.36px;
  margin: auto 0;
  font: 400 18px Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.header-icons {
  display: flex;
  gap: 8px;
}
.header-icon,
.filter-icon,
.icon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 28px;
}
.filter-title {
  color: #000;
  letter-spacing: 0.36px;
  margin-top: 24px;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.filter-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  max-width: 496px;
  gap: 20px;
  // padding: 0 20px;
}
.filter-option {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  width: 90%;
}
.filter-input {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 6px;
  background-color: #eee;
  color: #e15615;
  gap: 12px;
  white-space: nowrap;
  padding: 12px 20px;
  height: 48px;
  width: 100%;
}
.input-title {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
    sans-serif;
  margin: auto 0;
}
.arrow-icon {
  aspect-ratio: 1.49;
  object-fit: auto;
  object-position: center;
  width: 12px;
  fill: #000;
  margin: auto 0;
}
.placeholder {
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica,
    sans-serif;
  align-items: start;
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 6px;
  background-color: #eee;
  color: #6e6e6e;
  justify-content: center;
  padding: 12px 20px;
  height: 48px;
  width: 100%;
}
.modal-divider {
  border-top: 1px solid rgba(225, 86, 21, 1);
  align-self: stretch;
  margin-top: 36px;
  width: 100%;
}
.button-group {
  align-self: end;
  display: flex;
  gap: 16px;
  margin: 24px 20px 0 0;
}
.btn {
  display: flex;
  gap: 12px;
  border-radius: 6px;
  padding: 10px 20px;
}
.btn-reset {
  border-radius: 6px;
  border: 1px solid rgba(225, 86, 21, 1);
  background-color: #ffff;
  align-self: center;
  display: flex;
  gap: 12px;
  font-size: 18px;
  color: rgba(225, 86, 21, 1);
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
}
.btn-apply {
  border: 1px solid rgba(225, 86, 21, 1);
  background-color: #e15615;
  color: #fff;
  display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 48px;
    border-radius: 6px;
    font-size: 18px;
    white-space: nowrap;
    letter-spacing: 0.36px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
}

@media (max-width: 991px) {
  .filter-header {
    max-width: 100%;
  }
  .filter-container,
  .filter-title,
  .filter-input-group,
  .modal-divider {
    max-width: 100%;
  }
}