.issue-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.issue {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #fff;
  background-color: #e15615;
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: 400;
  letter-spacing: 0.48px;
  margin: 0 0 0.5rem 0;
  border-radius: 0.5rem;
}

.issue-icon {
  width: 1.5rem;
  margin-right: 1rem;
}
