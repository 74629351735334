.upload-biblografia-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid;
  padding: 5px 10px;
  background: white;
  color: #e15615;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: rgba(128, 128, 128, 0.171);
  }
}

.preveiw-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 1rem;

  .biblografia-pdf-container {
    padding: 1rem 2rem;
    border-radius: 8px;
    border: 1px solid #e15615;
    background-color: white;
    width: fit-content;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    button {
      background-color: white;
      border: none;
      cursor: pointer;

      &:hover > p {
        text-decoration: underline;
      }

      p {
        color: #e15615;
        font-weight: 700;
        text-align: center;
        margin-top: 4px;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .pdf-close-icon {
      position: absolute;
      top: 0;
      right: 0;
      background: white;
      border-radius: 50%;
      cursor: pointer;

      &.disabled {
        display: none;
      }
    }
  }
}
