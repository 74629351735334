
.right-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 520px) {
      display: none;
    }
  }

  .image-grid {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .grid-image {
    width: 28px;
    height: auto;
    cursor: pointer;
    position: relative;
    &:hover > .show-preview-labels{
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      transform: translate(-35%, -220%);
      width: max-content;
      padding: 10px 20px;
      font-size: 18px;
      height: 42px;
      color: #e15615;
      background-color: #F8DACC;
      border-radius: 25px;
      z-index: 1;
      &::after{
        content: "";
        position: absolute;
        bottom: 0px;
        width: 28px;
        height: 28px;
        border-radius: 3px;
        transform: rotate(45deg);
        z-index: -1;
        background-color: inherit;
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    margin: 10px;
    background-color: #000;
  }

  .show-preview-labels{
    display: none;
  }
  

.cassette-mobile-menu-icon{
  display: none;
  cursor: pointer;
  @media (max-width: 520px) {
    display: inline;
  }
}