.profile-card {
    align-self: stretch;
    display: flex;
    gap: 12px;
    cursor: pointer;
    position: relative;
  }

  .avatar-initials {
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
    border-radius: 32px;
    background-color: #e15615;
    color: #fff;
    font-size: 26px;
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 19px;
    letter-spacing: 0.52px;
  }
  @media (max-width: 520px) {
    .avatar-initials{
      width: 44px;
      height: 44px;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    color: #6e6e6e;
    margin: auto 0;
  }

  @media (max-width: 991px) {
    .user-info {
      display: none;
    }
  }
  .user-details {
    display: flex;
    gap: 4px;
    font-size: 18px;
    letter-spacing: 0.36px;
  }

  .user-name {
    font-size: 22px;
    font-family: Gilroy-Bold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .verified-icon {
    width: 20px;
    align-self: start;
  }

  .app-version {
    letter-spacing: 0.32px;
    margin-top: 4px;
    font: 16px Gilroy-Medium, sans-serif;
  }

  @media (max-width: 991px) {
    .avatar-initials {
      white-space: initial;
      padding-right: 20px;
    }
  }
  .profile-tooltip-options {
    width: max-content;
    border: 1px solid #dddddd;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: absolute;
    top: 30px;
    right: 0px;
    background: white;
    z-index: 10;
    border-radius: 5px;
  }
  .tooltip-option {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .administration-option {
    color: #e15615;
  }
  .logout-option {
    color: #6e6e6e;
  }
  @media (max-width: 520px) {
    .profile-tooltip-options {
      display: none;
    }
  }

  .groups-header {
    margin-top: 0rem !important;
    font: 800 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif !important;
    margin-bottom: 1rem!important;
  }

  .archeo-version {
    font: 200 14px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif !important;
    text-align: right;
  }