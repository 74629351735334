.referenti-selection-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .referenti-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .referenti-add-button {
      background: white;
      border: none;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .referento-selection-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    & > div {
      width: 100%;
    }

    input[name="referenti"] {
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      padding: 4px;
      height: 40px;
    }

    .referento-remove-btn {
      background: white;
      border: none;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
