.tooltip {
  position: absolute;
  background-color: #e15615;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  /* top: 100%; */
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 100;
}

.tooltip.visible {
  opacity: 1;
}
