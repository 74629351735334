.layer-match-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border: 1px dashed #e0e0e0;
  background-color: #f2f2f2;
  border-radius: 6px;

  .comune-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    & > p:first-child {
      font-weight: 500;
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.36px;
    }
  }

  .chiave-wrapper,
  .tipo-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;

    & > label:first-child {
      font-weight: 500;
      width: 30%;
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.36px;
    }

    & > input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }

  .crs-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    & > p:first-child {
      font-weight: 500;
      width: 30%;
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.36px;
    }

    & > div {
      width: 100%;
    }
  }
}
