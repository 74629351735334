.editor-container {
  max-width: 1220px;
  margin: 0 auto;
}

.editor-body {
  padding: 2rem 1rem;
  height: auto;
  overflow-y: auto; /* Add this line to enable vertical scrollbar */
  max-height: 500px; /* Set a max-height to ensure the scrollbar appears */
  border: 1px solid #ccc; /* Optional: Add a border to better visualize the container */
  background: white
}

.file-upload {
  visibility: hidden;
}
