.archeological-data {
  align-items: center;
  color: #6e6e6e;
  display: flex;
  font-size: 16px;
  gap: 6px;
  letter-spacing: 0.32px;
  margin-top: 4px;
  .single-bread-crum {
    display: none;
  }
  .multi-bread-crum {
    display: flex;
  }
  @media (max-width: 991px) {
    font-size: 14px;
    .single-bread-crum {
      display: flex;
    }
    .multi-bread-crum {
      display: none;
    }
  }
}

.data-label {
  align-self: stretch;
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
}
.data-icon {
  align-self: stretch;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
}
