.add-role-modal-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;

  .input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .input-label {
      font-size: 16px;
      font-weight: 500;
      color: #444050;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  }

  .input-field {
    .input-value {
      width: 100%;
    }
  }
}
