.class-summary-add-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  background-color: white;
}

.class-summary-add-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
}

.class-summary-add-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.class-summary-add-modal-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.class-summary-add-modal-form label {
  font-size: 1.25rem;
  font-weight: 500;
}

.class-summary-add-modal-form input {
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
}

.class-summary-add-modal-btns {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e0e0e0;
}
