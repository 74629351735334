.custom-select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .custom-select-label {
    color: #6e6e6e;
    font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    text-align: left;
    line-height: 16px;
    letter-spacing: 0.36px;
  }
}
