.cassette-record-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid rgb(221, 221, 221);
  background-color: #fff;
  gap: 20px;
  width: 100%;
  @media (max-width: 520px) {
    height: 76px;
  }
}

.record-info {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  align-items: flex-start;
}

.title {
  color: #000;
  font: 28px Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
  @media (max-width: 520px) {
    font-size: 18px;
  }
}

.details {
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 6px;
  font-size: 16px;
  color: #6e6e6e;
  letter-spacing: 0.32px;
}

.subheader-right-navbar-options-wrapper {
  display: none;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media (max-width: 520px) {
    display: flex;
    left: 0px !important;
    z-index: 3;
  }
}
.subheader-right-navbar-component{
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
  width: 100%;
}
.subheader-right-navbar-icons{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.subheader-right-navbar-options {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.separator-line{
  width: 100%;
  border-top: 1px solid #E15615;
}