.create-layer-container {
  width: 248px;
  height: 160px;
  position: absolute;
  top: 390px;
  right: 100px;
  border-radius: 6px;
  .create-layer-option-body-container {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    .upload-shape-file-container {
      width: 100%;
      height: 24px;
      .upload-shape-file {
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        width: fit-content;
        .upload-shape-file-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #e15615;
        }
      }
      .upload-file-input-container {
        height: 0;
        width: 0;
      }
    }
    .delete-shape-file-container {
      width: 100%;
      height: 24px;
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      .delete-shape-file-container {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #e15615;
      }
    }
  }
}
