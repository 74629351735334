.auth-page-container{
    width: 100%;
    height: calc(100% - 85px);
    background-image: url(../../assets/images/authPageImage.png);
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 520px) {
        background-size: 170% 150px;
        background-position-x:left;
      }
    
}