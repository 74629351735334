.repertori-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100&;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 24px;
}

.repertori-header-title {
  padding: 0;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  margin-left: 1rem;
}

.repertori-header {
  margin-top: 2rem;
  //border-top: 0.2rem solid rgba(221, 221, 221, 1);
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(221, 221, 221, 1);
}

.repertori-table-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100&;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 24px;
}

.repertori-table-header {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 0.2rem solid rgba(221, 221, 221, 1);
  border-bottom: 0.2rem solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px 20px;
  font: italic 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.repertori-row {
  // width:100%;
  align-items: center;
  display: flex;
  // padding-right: 46px;
  gap: 30px;
  flex: 1;
  justify-content: space-between;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  border-bottom-width: 1px;
  background-color: #fff;
  padding: 4px 20px;
}

.repertori-rowt > * {
  padding: 12px 0;
}

.repertori-title-text {
  background-color: #fff;
  padding: 12px 0;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.repertori-row-item-text {
  color: #616161;
  align-self: stretch;
  margin: auto 0;
  display: flex;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  width: 22%;
  min-width: 300px;
  // @media (max-width: 1435px) {
  // }
  word-break: break-all;
}

.repertori-icons {
  margin: 1rem;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.repertori-button {
  border: 0;
  margin-right: 0.5rem;
  cursor: pointer;
}

.repertori-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}
