.add-new-bibliografia {
  padding: 0.5rem;
  max-height: 500px;
  overflow-y: auto;

  .input-fields-column {
    flex-direction: column;
  }

  .class-summary-add-modal {
    width: 100%;
  }
}
