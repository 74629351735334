.modal-container {
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.modal-header {
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid rgba(225, 86, 21, 1);
  background-color: #f8dacc;
  width: 100%;
  padding: 14px 20px;
}

.modal-header-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.modal-footer {
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid rgba(225, 86, 21, 1);
  background-color: #f8dacc;
  width: 100%;
  padding: 14px 20px;
  display: flex;

  .modal-footer-button {
    color: #e15615;
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 6px;
    border: 1px solid #e15615;
    padding: 8px 16px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      background-color: #e15615;
      color: white;
    }

    &.disable {
      background-color: #f8dacc;
      color: #e15615;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

@media (max-width: 991px) {
  .modal-header-content {
    flex-wrap: wrap;
  }
}

.modal-title {
  color: #e15615;
  letter-spacing: 0.36px;
  margin: auto 0;
  font: 400 18px Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;

  @media (max-width: 520px) {
    font-size: 14px;
  }
}

.modal-icons {
  display: flex;
  gap: 8px;
}

.modal-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 28px;
}

.modal-upload-icon {
  aspect-ratio: 1;
  object-fit: contain;
  width: 84px;
}

.modal-upload-text {
  color: #6e6e6e;
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
}

.modal-browse-button {
  color: #e15615;
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}
