.container {
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 24px;
  min-height: 300px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 24px 18px;
}

@media (max-width: 520px) {
  .modal-content {
    max-width: 350px;
    overflow: auto;
  }
}

.record-main-content {
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  align-items: center;

  .modal-content {
    width: 600px;
  }
}

button:disabled {
  background-color: #f2f2f2;
  color: #b3b3b3;
  cursor: auto;
}

.group-wrapper,
.actions-wrapper {
  display: flex;
}

.group-wrapper {
  gap: 8px;
}

.actions-wrapper {
  align-items: center;
  gap: 4px;
}

.badge {
  padding: 4px 16px;
  border-radius: 40px;
  position: relative;
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: bold;
}

.save-button,
.cancel-button {
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  align-self: center;
  display: flex;
  gap: 12px;
  font-size: 18px;
  white-space: nowrap;
  letter-spacing: 0.36px;
  padding: 10px 20px;
  cursor: pointer;
}

.save-button {
  background-color: #f2f2f2;
  color: #fff;
}

.cancel-button {
  background-color: #fff;
  color: #000;
}

@media (max-width: 991px) {
  .save-button,
  .cancel-button {
    white-space: initial;
  }
}

@media (max-width: 520px) {
  .modal-content {
    max-width: 350px;
    overflow: auto;
  }
}
