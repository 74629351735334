.login-page-container {
  width: 90%;
  display: flex;
  flex-direction: column;

  .login-title,
  .login-description {
    font-weight: 500;
    color: #000;
    text-align: center;
    width: 100%;
    text-wrap: wrap;
    word-break: keep-all;
  }

  .login-title {
    font: 28px/39px Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .login-description {
    font-weight: 200;
    font: 16px/32px Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .intro-image-frame1,
  .intro-image-frame2 {
    padding: 8px;
    width: 100%;
    overflow: hidden;
  }

  .intro-image-frame1 {
    border: 2px solid #e15615;
    border-radius: 6px;
  }

  .intro-image {
    height: 400px;
    width: 140%;
    object-fit: cover;
    margin: 0 auto;
    filter: sepia(100%);
    animation: moveImage 20s infinite alternate;
  }

  .login-text {
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
    margin: auto 0;
  }

  .login-icon {
    width: 28px;
    height: 28px;
  }

  @keyframes moveImage {
    0% {
      transform: translateX(0px) scale(1);
    }
    50% {
      transform: translate(-50px) scale(1.2);
    }
    100% {
      transform: translateX(-100px) scale(1);
    }
  }

  .login-button {
    border-radius: 6px;
    border: 1px solid rgba(225, 86, 21, 1);
    background-color: #e15615;
    align-self: center;
    display: flex;
    margin-top: 36px;
    gap: 12px;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    letter-spacing: 0.36px;
    padding: 10px 20px;
    cursor: pointer;
    overflow: hidden;
  }
}

@media (max-width: 991px) {
  .login-button {
    white-space: initial;
  }
}
