@import "../../styles/colors.scss";

.tooltip-container {
   
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip-text {
      // border: 0.1rem solid $archeo-gray;
      font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      visibility: hidden;
      width: auto;
      background-color: #F8DACC;
      color: $archeo-color;
      text-align: center;
      border-radius: 25px;
      padding: 0.5rem 1rem;
      position: absolute;
      z-index: 1;
      bottom: 50px; //Position above the element 
      right: 0;
      opacity: 0;
      transition: opacity 0.3s;
      white-space: nowrap;
    }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-text::after  {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    transform: rotate(45deg) translate(-10px, 20px);
    z-index: -1;
    background-color: inherit;
  }

