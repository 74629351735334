/* General Styles */
body {
    //font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  /* Container for the manual */
  .manual-container {
    display: flex;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Table of Contents */
  .table-of-contents {
    background-color: #fff;
    flex: 1;
    width: 100%;
    //margin-right: 20px;
  }
  
  .manual-header {
    font-size: 2em;
    margin: 0 0 20px;
    color: #333;
  }
  
  .chapter-navigation {
    list-style-type: none;
    padding: 0;
  }
  
  .chapter-navigation li {
    margin-bottom: 10px;
  }
  
  .chapter-button {
    display: flex;
    align-items: center;
    background-color: #fff;
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    //background-color: #007bff;
    //color: #fff;
    border: none;
    padding: 10px 20px;
    //border-radius: 4px;
    cursor: pointer;
    //transition: background-color 0.3s;
    text-align: left;
    width: 100%;
  }

  .toc-button {
    display: flex;
    align-items: center;
    background-color: #fff;
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    //background-color: #007bff;
    //color: #fff;
    border: none;
    padding: 10px 20px;
    //border-radius: 4px;
    cursor: pointer;
    //transition: background-color 0.3s;
    text-align: left;
    width: 100%;
  }
  
  .chapter-button:hover {
    background-color: #eee;
  }

  .chapter-title {
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    padding-left: 1rem;
    color: #999;
  }
  .chapter-title-marked {
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    padding-left: 1rem;
    color: #333
  }
  
  /* Subchapter Styles */
  .subchapter-navigation {
    list-style-type: none;
    //padding-left: 20px;
  }
  
  .subchapter-button {

    background-color: #fff;
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    //background-color: #6c757d;
    //color: #fff;
    border: none;
    padding: 8px 20px;
    border-radius: 4px;
    cursor: pointer;
    //transition: background-color 0.3s;
    text-align: left;
    width: 100%;
  }
  
  .subchapter-button:hover {
    background-color: #bbb;
  }

  .subchapter-title {
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    padding-left: 3rem;
    color: #999;
  }

  .subchapter-title-marked {
    font: 800 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    padding-left: 3rem;
    color: #333;
  }

  /* Chapter Content */
  .chapter-content {
    flex: 2;
    padding-left: 20px;
    border-left: 1px solid #ddd;
  }
  
  /* Footer Styles */
  .manual-footer {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9em;
    color: #666;
  }

  .table-of-contents-compressed {
    position: fixed;
    top: 5rem;
    right: 0;
    @media (min-width: 775px) {
      display: none;
    }
    border-radius: 25px;
  }

  .table-of-contents-uncompressed {
    @media (max-width: 775px) {
      display: none;
    }
  }

  /******************************************************************************************/
  /* MARKDOWN STYLES */


  .markdown-content {
    line-height: 1.6;
    color: #333;
  }
  
  .markdown-content h2 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #e15615;
  }
  
  .markdown-content p {
    margin: 10px 0;
  }
  
  .markdown-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .markdown-content a:hover {
    text-decoration: underline;
  }
  
  .markdown-content ul,
  .markdown-content ol {
    margin: 10px 0 10px 20px;
  }
  
  .markdown-content code {
    background-color: #f8f8f8;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .markdown-content pre {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }
  .markdown-content blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    border-left: 5px solid  #e15615;
    background-color: #f9f9f9;
    color: #555;
    font-style: italic;
    font-size: 1.1em;
    quotes: "“" "”" "‘" "’";
  
    &::before {
      content: open-quote;
      font-size: 2em;
      line-height: 0;
      margin-right: 10px;
      vertical-align: -0.4em;
      color: #e15615;
    }
  
    &::after {
      content: close-quote;
      font-size: 2em;
      line-height: 0;
      margin-left: 10px;
      vertical-align: -0.4em;
      color: #e15615;
    }
  
    p {
      display: inline;
    }
  }

