.field-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 2rem
}

.field-sub-wrapper {
  display: flex;
  align-items: right;
  flex-direction: row;
  margin-left: auto;
  gap: 0.5rem;
}

.field-wrapper p.element-header-status {
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0.5rem;
  gap: 1rem;
}

.field-wrapper .element-header-status-badge {
  font-size: 10px;
  font-weight: 400;
  width: fit-content;
  padding: 4px 7px;
  border-radius: 8rem;
  border: #e15615d3 1px solid;
  background-color: #e15615;
  color: white;
}
.field-wrapper .element-header-status-description {
  font-size: 8px
}


@media screen and (max-width: 768px) {
  .field-wrapper p.element-header-status > span:first-child {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .field-wrapper .field-sub-wrapper {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .field-wrapper p.element-header-status {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
