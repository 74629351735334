.form-element-container {
  position: relative;
  border-bottom: 2px solid #dbd5d5de;
}

.form-element-header {
  max-width: 8rem;
  margin-left: 1rem;
}

.parent-form-element {
  position: relative;
  padding: 1rem;
  cursor: pointer;
  text-align: left;
  border-radius: 0.5rem;
  margin-bottom:0.1rem;
}

.parent-form-element.collapsed-open {
  background-color: #dbd5d5de;
}

.parent-form-element.has-children:hover {
  background-color: #dbd5d5de;
}

.form-element-wrapper {
  display: flex;
}

.form-element-wrapper > div {
  width: 50%;
}

.form-element-wrapper > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.has-children {
  background-color: #f0f0f0;
}

.collapse-icon {
  position: absolute;
  display: none;
  width: 1rem;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.collapse-icon.show {
  display: block;
}

.parent-form-element .form-element-acronym {
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
}

.parent-form-element .form-element-definition {
  font-style: italic;
  font-size: 0.5rem;
}

.form-element-container p {
  margin: 0;
}

.form-element-child {
  display: none;
  padding: 1rem;
}

.form-element-child.show {
  display: block;
}

.form-child-field {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  position: relative;
  width: 100%;
}

.use-icon {
  position: absolute;
  width: 1.5rem;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.use-icon > p {
  font-size: 2rem;
}

.form-child-field input {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.form-child-field .grow-textarea {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: #dbd5d5de 1px solid;
  font-weight: 400;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #555;
  pointer-events: none;
  display: block;
}

.form-child-field .min-max {
  font-size: 10px;
  text-align: right;
  padding: 0.5rem 0 0;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
}

.form-child-field .min-max .input-warning-msg {
  color: red;
}

.form-child-input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.repeat-element-add {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: greenyellow;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
}

@media screen and (max-width: 768px) {
  /* .form-element-wrapper:not(:has(.toggle)) {
    flex-wrap: wrap;
    flex-direction: column;
  } */

  .form-element-wrapper:not(:has(.toggle)) > div {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .form-element-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .form-element-wrapper > div {
    width: 100%;
  }

  .form-element-wrapper > div .toggle {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
