.header {
  background-color: #fff;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  box-shadow: 0px 4px 40px 0px rgba(238, 241, 248, 0.25);
  display: flex;
  font-weight: 400;
  gap: 20px;
  justify-content: space-between;
  padding: 14px 24px;
  @media (max-width: 520px) {
    height: 76px;
  }
}

.logo-container {
  color: #000;
  display: flex;
  gap: 20px;
  @media (max-width: 520px) {
    // display: none;
  }
}

.logo {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 56px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  @media (max-width: 991px) {
    display: none;
  }
}

.logo-title {
  font: 18px Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.36px;
  margin: 0;
}

.logo-subtitle {
  font: 16px Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  letter-spacing: 0.32px;
  margin: 8px 0 0;
}

.navigation {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: auto 0;
  font-size: 18px;
  @media (max-width: 520px) {
    font-size: 14px;
    width: 100%;
  }
}


.nav-link {
  align-self: stretch;
  color: #6e6e6e;
  font-size: inherit;
  margin: auto 0;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.nav-link.active {
  color: #e15615;
  text-decoration: underline;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: auto 0;
}


.email-container,
.phone-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-container {
  width: 52px;
  height: 52px;
  border-radius: 30px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.icon {
  width: 28px;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
}

.email,
.phone {
  color: #000;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  margin: auto 0;
  @media (max-width: 991px) {
    display: none;
  }
}

.page-title {
  color: #000;
  font: 400 28px Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  margin-top: 266px;
}

.mobile-view-logo {
  height: 80px;
  display: none;
  @media (max-width: 520px) {
    display: flex;
  }
}


.desktop-view-logo {
  height: 60px;
  @media (max-width: 520px) {
    display: none;
  }
}
.floating-menu {
  background-color: #e15615;
  color: #fff;
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: 10000;
  box-shadow: 0px 4px 40px 0px rgba(238, 241, 248, 0.25);
  padding: 14px 24px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.floating-label {
  font: 400 16px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  color: #fff;
  margin: 0;
}
