.warning-modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.warning-modal-icon {
  color: #f0ad4e;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.warning-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.warning-modal-button {
  background-color: #e15615;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.warning-modal-button:hover {
  background-color: #d64d0e;
}
