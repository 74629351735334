.pagination {
    justify-content: space-between;
    display: flex;
    margin-top: 16px;
    width: 100%;
    gap: 20px;
    font-size: 18px;
    font-weight: 400;
    padding: 0 20px;
  }

  .pagination__info {
    display: flex;
    gap: 20px;
    color: var(--Black-Black-100, #000);
    justify-content: space-between;
  }
  @media (max-width: 991px) {
    .pagination__info {
      gap:5px;
    }
  }

  .pagination__current-page,
  .pagination__records-end,
  .pagination__total-records {
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .pagination__separator,
  .pagination__label {
    color: #6e6e6e;
    font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .pagination__per-page {
    border-color: rgba(121, 121, 121, 1);
    border-style: solid;
    border-width: 0px;
    padding: 1px;
    border-bottom-width: 1px;
    display: flex;
    gap: 12px;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    .pagination__per-page {
      white-space: initial;
    }
  }

  .pagination__per-page-label {
    font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .pagination__per-page-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }

  .pagination__nav {
    display: flex;
    gap: 16px;
    white-space: nowrap;
  }

  @media (max-width: 991px) {
    .pagination__nav {
      white-space: initial;
    }
  }

  .pagination__nav-info {
    align-self: start;
    display: flex;
    gap: 8px;
  }

  @media (max-width: 991px) {
    .pagination__nav-info {
      white-space: initial;
    }
  }

  .pagination__nav-label {
    color: #6e6e6e;
    font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .pagination__nav-page {
    color: var(--Black-Black-100, #000);
    font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
      sans-serif;
  }

  .pagination__nav-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }