.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.3);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  border-top-color: #fff;
  animation: spin 1s linear infinite;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
