.edit-user-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;

  .input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .input-label {
      font-size: 16px;
      font-weight: 500;
      color: #444050;
    }

    .username-text {
      font-weight: 500;
      color: #444050;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 8px;
    }

    .pwd-btn {
      position: absolute;
      right: 0;
      top: -8px;
      padding: 0;
      border: none;
      background-color: white;
      cursor: pointer;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
