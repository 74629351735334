.reset-password-container {
  display: flex;
  gap: 24px;
  align-items: center;
  height: 381px;
  flex-direction: column;
  .header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    .reset-password-title {
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      text-align: left;
      color: #000000;
    }
    .reset-password-description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #6e6e6e;
    }
  }
  .password-strength {
    display: flex;
    width: 100%;
    justify-content: left;
    gap: 8px;
    .strength-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 22.05px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #ee4b2b;
    }
  }
  .reset-password-button {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 6px;
    border: 1px solid #e15615;
    background: #e15615;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    .button-text {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }

  .reset-password-button-disabled {
    width: 100%;
    height: 48px;
    padding: 12px 20px;
    border-radius: 6px;
    border: 1px solid lightgray;
    background: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    .button-text {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }

  .password-status-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 40%;
    max-width: 496px;
    max-height: 400px;
    min-height: 300px;
    min-width: 350px;
    border-radius: 6px;
    border: 1px solid #dddddd;
    background: #ffffff;
    @media (max-width: 520px) {
      width: 100%;
      top: auto;
      left: auto;
      bottom: 0%;
      transform: translate(0, 0);
    }
    .status-card-header {
      width: 100%;
      height: 56px;
      border-radius: 6px 6px 0px 0px;
      border: 0px 0px 1px 0px;
      background: #f8dacc;
      border-bottom: 1px solid #e15615;
      color: #e15615;
      font-size: 18px;
      font-weight: 500;
      line-height: 22.05px;
      letter-spacing: 0.02em;
      text-align: left;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    .status-card-body {
      width: 100%;
      height: calc(100% - 56px);
      display: flex;
      align-items: center;
      justify-content: center;
      .content-container {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 24px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .text-container {
          text-align: center;
          padding: 0 30px;
        }
        .staus-login-button {
          width: 127px;
          height: 48px;
          padding: 12px 20px;
          gap: 12px;
          border-radius: 6px;
          border: 1px solid #e15615;
          background-color: #e15615;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          .button-text {
            font-size: 18px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.input-label {
  color: #000;
  font-weight: 500;
}
