.sidebar-social-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin-top: 20px;
  border-radius: 30px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;

  @media (max-width: 520px) {
    margin: 0;
    width: 36px;
    height: 36px;
  }

  &:hover>.show-preview-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 62px;
    width: max-content;
    padding: 10px 20px;
    font-size: 18px;
    height: 42px;
    color: #e15615;
    background-color: #F8DACC;
    border-radius: 25px;
    z-index: 5;

    @media (max-width: 520px) {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      right: 0px;
      width: 28px;
      height: 28px;
      border-radius: 3px;
      transform: rotate(45deg);
      z-index: -1;
      background-color: inherit;
    }
  }
}


.sidebar-social-icon-container.active {
  background-color: #e15615;
}

.show-preview-label {
  display: none;
}

// .social-icon img {
//   width: 28px;
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
// }