.blocks-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout for mobile */
  gap: 1rem;
  width: 100%;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Three columns layout for larger screens */
    // grid-auto-flow: row dense; /* Fill in gaps with smaller items */
  }
}

.form-block {
  //flex: 1 1 calc(50% - 1rem); /* Adjust the width of each block to be half of the container minus the gap */
  //min-width: calc(50% - 1rem);
  min-width: 400px;
  box-sizing: border-box;
  box-shadow: 1px 3px 2px 2px #aaaa;
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 16px;
}

.form-block-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  //align-items: center;
  //margin-bottom: 1rem;
}

.form-block-header-title {
  font: 400 24px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  color: $archeo-gray;
  text-transform: uppercase;
}

.form-block-button {
  width: 12rem;
  height: 2.5rem;
  margin-right: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font: 400 14px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  // color: #fff;
  //background-color: rgba(221, 221, 221, 1);;
  border: 0.15rem solid $archeo-gray;
  margin: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  gap: 1rem;
}

.section-title {
  font: 400 24px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  color: $archeo-gray;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.input-fields-column {
  display: flex;
  gap: 20px;
  width: 100%;
}

@media (max-width: 520px) {
  .input-fields-column {
    width: 100%;
    flex-direction: column;
  }
}

.input-field {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  letter-spacing: 0.36px;
}

.input-label {
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.input-value {
  font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  border-radius: 6px;
  border: 1px solid rgba(221, 221, 221, 1);
  margin-top: 8px;
  padding: 12px 20px;
  background-color: "#fffff";
  height: 48px;
}

.input-value:disabled {
  background-color: #dddddd;
}
@media (max-width: 520px) {
  .input-value {
    white-space: initial;
  }
}
