.color-wrapper {
  width: 150px;
  height: 30px;
  border: 1px solid #e15615;
  border-radius: 4px;
}

// .user-initials {
//   align-items: center;
//   background-color: #e15615;
//   border-radius: 32px;
//   color: #fff;
//   display: flex;
//   flex-direction: column;
//   font-size: 26px;
//   height: 52px;
//   justify-content: center;
//   letter-spacing: 0.52px;
//   padding: 0 19px;
//   white-space: nowrap;
//   width: 52px;
// }

// @media (max-width: 991px) {
//   .user-initials {
//     padding-right: 20px;
//     white-space: initial;
//   }
// }

// .initials {
//   font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
// }

// .user-avatar {
//   align-self: start;
//   aspect-ratio: 1;
//   object-fit: auto;
//   object-position: center;
//   width: 20px;
// }

// .group-detail-main-content {
//   // background-color: #fff;
//   border-bottom: 1px solid rgba(221, 221, 221, 1);
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
//   // justify-content: space-between;
//   // padding: 20px 24px;
//   width: 100%;
//   height: 100%;
//   align-items: center;
// }

// .profile-card {
//   align-self: stretch;
//   display: flex;
//   gap: 12px;
// }

// .avatar-initials {
//   font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
//   border-radius: 32px;
//   background-color: #e15615;
//   color: #fff;
//   font-size: 26px;
//   width: 52px;
//   height: 52px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0 19px;
//   letter-spacing: 0.52px;
// }

// .user-info {
//   display: flex;
//   flex-direction: column;
//   color: #6e6e6e;
//   margin: auto 0;
// }

// .user-details {
//   display: flex;
//   gap: 4px;
//   font-size: 18px;
//   letter-spacing: 0.36px;
// }

// .user-name {
//   font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
// }

// .verified-icon {
//   width: 20px;
//   align-self: start;
// }

// .user-role {
//   letter-spacing: 0.32px;
//   margin-top: 4px;
//   font: 16px Gilroy-Medium, sans-serif;
// }

// @media (max-width: 991px) {
//   .avatar-initials {
//     white-space: initial;
//     padding-right: 20px;
//   }
// }
