.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  

  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-actions button {
    margin-left: 10px;
  }
  .form{
    display: grid;
    grid-gap: 10px;
    height: 400px;
    overflow-y: auto;
  }
  .form input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

/* Custom scroll bar styling */
.form::-webkit-scrollbar {
  width: 13px; 
}

.form::-webkit-scrollbar-thumb {
  background-color: rgb(225, 86, 21); 
  border-radius: 3px; 
}

.form::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the track */
}
.cancel-button {
  color: #e15615;
  border: 1px solid;
}
.save-button {
  background-color: #e15615;
  color: white;
}
  