.upload-pdf-container {
  margin-top: 1rem;
  padding: 1rem 0.5rem;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 6px;

  .upload-pdf-header-title {
    font: 500 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
    color: #6e6e6e;
    text-transform: uppercase;
  }
}

.upload-biblografia-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid;
  padding: 2px 5px;
  background: white;
  color: #e15615;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: rgba(128, 128, 128, 0.171);
  }
}

.allegato-preview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 400px;
  gap: 8px;
  margin-top: 1rem;
  justify-content: center;

  .biblografia-pdf-container {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #e15615;
    background-color: white;
    width: fit-content;
    max-width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    button {
      background-color: white;
      border: none;
      cursor: pointer;

      &:hover > p {
        text-decoration: underline;
      }

      p {
        color: #e15615;
        font-weight: 700;
        text-align: center;
        margin-top: 4px;
      }

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .pdf-close-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      background: white;
      border-radius: 50%;
      cursor: pointer;

      &.disabled {
        display: none;
      }
    }
  }
}
