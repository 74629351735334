.legend-info-card-container {
  width: 248px;
  top: 175px;
  right: 100px;

  .layer-icon-and-text-wrappper {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .legend-title-text {
      color: #6e6e6e;
    }
  }
}
