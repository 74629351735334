#generalità { order: 1; }
#galleria { order: 2; }
#bibliografia { order: 5; }
#provenienza { order: 3; }
#caratteristiche { order: 4; }
#collocazione { order: 6; }
#scheda { order: 7; }


@media (max-width: 768px) {
  #generalità { order: 1; }
  #galleria { order: 2; }
  #bibliografia { order: 5; }
  #provenienza { order: 3; }
  #caratteristiche { order: 4; }
  #collocazione { order: 6; }
  #scheda { order: 7; }
}

.class-iccd-btn {
  background-color: #e15615;
  border-color: #e15615;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  border-width: 0;
}

.iccd-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

