.feature-summary-container {
  position: absolute;
  bottom: 100px;
  right: 100px;
  width: 40%;
  height: 75%;
  min-width: 400px;
  max-width: 558px;
  max-height: 719px;
  border-radius: 6px;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  background: rgba(255, 255, 255, 1);
  @media (max-width: 520px) {
    right: auto;
    bottom: 0;
    background: none;
    height: fit-content;
  }
  .feature-table-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    .summary-container {
      display: flex;
      gap: 14px;
      flex-direction: column;
      .summary-header {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.05px;
        letter-spacing: 0.02em;
        text-align: left;
        @media (max-width: 520px) {
          font-size: 14px;
          line-height: normal;
        }
      }
      .summary-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 25.2px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #6E6E6E;
        @media (max-width: 520px) {
          font-size: 14px;
          line-height: normal;
        }
      }
    }
  }
}

.feature-summary-instructions {
  padding: 2rem;
  font: 400 24px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  color: "#6E6E6E";
  //text-transform: uppercase;
  margin-bottom: 1rem
}
