.option-card-main-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  max-height: 100vh;
  overflow: hidden;
  @media (max-width: 520px) {
    position: fixed;
    width: 100vw !important;
    max-height: 45vh;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: 0px;
    border-radius: 8px 8px 0 0;
  }
  @media (max-width: 520px) {
    &::before {
      // content: "";
      // position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: -1;
    }
  }
}
.option-card-sub-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-height: 100%;
  overflow: hidden;
  @media (max-width: 520px) {
    position: relative;
    z-index: 1000;
    border-radius: 8px 8px 0 0;
  }
}
.option-card-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F8DACC;
  color: #e15615;
  border-bottom: 1px solid;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
}

.option-card-main-header {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
}
.option-card-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  .option-card-icon {
    cursor: pointer;
  }
}
.option-card-children-wrapper {
  padding: 12px 16px;
  background-color: white;
  max-height: 100%;
  overflow: auto;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  @media (max-width: 520px) {
    padding-bottom: 20px;
    border-radius: 0;
  }
}
