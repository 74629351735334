.map-view-main-container {
  position: relative;
  display: flex;
  width: 100vw;
  min-height: 90vh;
  height: calc(100vh - 85px);
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 520px) {
    flex-direction: column-reverse;
    height: calc(100vh - 75px);
  }
  .ol-map-container-wrapper-class {
    height: 100%;
    width: 100%;
    .ol-map-container {
      height: 100%;
      width: 100%;
    }
  }
}


.mapview-logo {
  width: 36px;
  aspect-ratio: 0.5;
  object-fit: contain;
  object-position: center;
  align-self: end;
  position: relative;
  bottom: 20px;
  left: 20px;
}

@media (max-width: 520px) {
  .mapview-logo {
    margin-top: 40px;
  }
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  background-color: #fff;
  border-left: 1px solid rgba(221, 221, 221, 1);
  box-shadow: -4px 0px 40px 0px rgba(238, 241, 248, 0.25);
  @media (max-width: 520px) {
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 20px;
    height: 76px;
    justify-content: space-between;
  }
}

.option-card-main-container.municipality-info-card-wrapper {
  top: 100px;
  right: 100px;
  width: 372px;
}