// .container {
//   background-color: #f2f2f2;
//   display: flex;
//   flex-direction: column;
//   padding-bottom: 24px;
// }

//   .user-info {
//     align-self: stretch;
//     display: flex;
//     gap: 12px;
//   }

.user-initials {
  align-items: center;
  background-color: #e15615;
  border-radius: 32px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  height: 52px;
  justify-content: center;
  letter-spacing: 0.52px;
  padding: 0 19px;
  white-space: nowrap;
  width: 52px;
}

@media (max-width: 991px) {
  .user-initials {
    padding-right: 20px;
    white-space: initial;
  }
}

.initials {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
}

//   .user-details-block {
//     display: flex;
//     flex-direction: column;
//   }

//   .user-details {
//     color: #6e6e6e;
//     display: flex;
//     // flex-direction: column;
//     font-size: 18px;
//     gap: 4px;
//     letter-spacing: 0.36px;
//     margin: auto 0;
//   }

//   .user-name {
//     font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
//   }

.user-avatar {
  align-self: start;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}

//   .user-role {
//     font: 16px Gilroy-Medium, sans-serif;
//     letter-spacing: 0.32px;
//     margin-top: 4px;
//   }

.user-detail-main-content {
  // background-color: #fff;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  // justify-content: space-between;
  // padding: 20px 24px;
  // width: 100%;
  height: 100%;
  align-items: center;
}

.action-icon {
  cursor: pointer;
}

//   .breadcrumbs {
//     align-items: center;
//     color: #6e6e6e;
//     display: flex;
//     font-size: 16px;
//     font-weight: 400;
//     gap: 6px;
//     justify-content: space-between;
//     letter-spacing: 0.32px;
//     margin-top: 4px;
//   }

//   @media (max-width: 991px) {
//     .breadcrumbs {
//       flex-wrap: wrap;
//     }
//   }

//   .breadcrumb-items {
//     align-self: stretch;
//     display: flex;
//     gap: 6px;
//     margin: auto 0;
//   }

//   .breadcrumb-link {
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//     text-decoration: none;
//   }

//   .breadcrumb-separator {
//     align-self: stretch;
//     aspect-ratio: 1;
//     object-fit: auto;
//     object-position: center;
//     width: 24px;
//   }

//   .breadcrumb-current {
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .action-icons {
//     display: flex;
//     gap: 20px;
//     justify-content: space-between;
//     margin: auto 0;
//   }

//   .action-icon {
//     aspect-ratio: 1;
//     object-fit: auto;
//     object-position: center;
//     width: 28px;
//   }

//   .progress-bar {
//     background-color: var(--Black-Black-700, #e6e6e6);
//     display: flex;
//     flex-direction: column;
//     margin-top: 702px;
//   }

//   @media (max-width: 991px) {
//     .progress-bar {
//       margin-top: 40px;
//       max-width: 100%;
//       padding-right: 20px;
//     }
//   }

//   .progress {
//     background-color: #e15615;
//     height: 8px;
//     max-width: 100%;
//     width: 400px;
//   }

//   .pagination {
//     display: flex;
//     font-size: 18px;
//     font-weight: 400;
//     gap: 20px;
//     justify-content: space-between;
//     margin-top: 16px;
//     padding: 0 20px;
//     // width: 100%;
//   }

//   @media (max-width: 991px) {
//     .pagination {
//       flex-wrap: wrap;
//       max-width: 100%;
//     }
//   }

//   .pagination-info {
//     align-self: start;
//     color: var(--Black-Black-100, #000);
//     display: flex;
//     gap: 8px;
//     justify-content: space-between;
//   }

//   .pagination-range {
//     font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .pagination-separator {
//     color: #6e6e6e;
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .pagination-total {
//     font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .pagination-label {
//     color: #6e6e6e;
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .page-size {
//     border-bottom: 1px solid rgba(121, 121, 121, 1);
//     display: flex;
//     gap: 12px;
//     white-space: nowrap;
//   }

//   @media (max-width: 991px) {
//     .page-size {
//       white-space: initial;
//     }
//   }

//   .page-size-label {
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .page-size-icon {
//     aspect-ratio: 1;
//     object-fit: auto;
//     object-position: center;
//     width: 24px;
//   }

//   .page-navigation {
//     display: flex;
//     gap: 16px;
//     white-space: nowrap;
//   }

//   @media (max-width: 991px) {
//     .page-navigation {
//       white-space: initial;
//     }
//   }

//   .page-nav-icon {
//     aspect-ratio: 1;
//     object-fit: auto;
//     object-position: center;
//     width: 24px;
//   }

//   .current-page {
//     align-self: start;
//     display: flex;
//     gap: 8px;
//   }

//   @media (max-width: 991px) {
//     .current-page {
//       white-space: initial;
//     }
//   }

//   .page-label {
//     color: #6e6e6e;
//     font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
//   }

//   .page-number {
//     color: var(--Black-Black-100, #000);
//     font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
//   }

.profile-card {
  align-self: stretch;
  display: flex;
  gap: 12px;
}

.avatar-initials {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
  border-radius: 32px;
  background-color: #e15615;
  color: #fff;
  font-size: 26px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  letter-spacing: 0.52px;
}

.user-info {
  display: flex;
  flex-direction: column;
  color: #6e6e6e;
  margin: auto 0;
}

.user-details {
  display: flex;
  gap: 4px;
  font-size: 18px;
  letter-spacing: 0.36px;
}

.user-name {
  font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica, sans-serif;
}

.verified-icon {
  width: 20px;
  align-self: start;
}

.user-role {
  letter-spacing: 0.32px;
  margin-top: 4px;
  font: 16px Gilroy-Medium, sans-serif;
}

@media (max-width: 991px) {
  .avatar-initials {
    white-space: initial;
    padding-right: 20px;
  }
}

.reverted-edit-icon {
  transform: rotate(180deg);
}
.email {
  color: #616161;
}
.header-text {
  color: #000000;
}
