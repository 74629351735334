
.raccolta {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-style: solid;
  border-radius: 0 0 6px 6px;
  border-color:   #F8DACC;
  border-width: 0.2rem;
  margin-bottom: 1rem;
 //background-color: #e9e9e9;
}

.raccolta-header {
 display: flex;
 flex-direction: row;
 gap: 1rem;
 // flex-direction: row-reverse;
 padding-bottom: 1rem;
 border-bottom: 1px solid #e6e6e6;
}

.raccolta-icon {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.reperti-reference {
  background-color: #e6e6e6;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}


.jump-button {
  margin-right: 0%;
  border: 0;
  cursor: pointer;
  background-color: transparent
}



.id-text {
  font: 800 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
  padding: 10px 0px;

}

.copy-text {
  margin-left: 0;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
}