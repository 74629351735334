.add-reperti-bibliografia-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;

  .reperti-filter-section {
    display: flex;
    width: 100%;
    button {
      padding: 5px 12px;
      border-radius: 5px;
      border: none;
      background-color: #e15615;
      color: white;
      font-weight: bold;
      cursor: pointer;

      &:hover {
        background-color: #e15615dd;
      }
    }

    input {
      margin-left: auto;
      padding: 0.5rem;
      border-radius: 5px;
      border: 1px solid #555;
      font-size: 16px;
    }
  }

  .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .input-label {
      font-size: 16px;
      font-weight: 500;
      color: #444050;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
