.option-card-main-container.delete-layer-card-wrapper{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 372px;
    @media (max-width: 520px) {
     transform: translate(0%, 0%);
    }
}

.delete-layers-button-wrapper{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: flex-end;
    gap: 20px;
}

.action-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 48px;
    border-radius: 6px;
    font-size: 18px;
    white-space: nowrap;
    letter-spacing: 0.36px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 500;
  }
  .cancel-button {
    color: #e15615;
    border: 1px solid;
  }
  .delete-button {
    background-color: #e15615;
    color: white;
    border: transparent;
  }