.association-section-container {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 0;
  .select-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .xls-header-label {
      color: #6e6e6e;
      font-family: Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
      text-align: left;
      line-height: 16px;
      letter-spacing: 0.36px;
    }
  }
}
