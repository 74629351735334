.class-summary-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: 60px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.class-summary-header-title {
  display: block;
  font: 400 18px Gilroy-Medium, -apple-system, Roboto, Helvetica, sans-serif;
}

.class-summary-header .summary-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.class-button {
  margin-right: 0%;
  border: 0;
  cursor: pointer;
  background-color: transparent
}

@media screen and (max-width: 768px) {
  .class-summary-header-title {
    display: none;
  }
}
